import { toast } from 'react-toastify';
import React from 'react';
import NotificationsMessage from './NotificationsMessage';
import {
  ERROR,
  INFO,
  LONG_TERM_TYPE, MD,
  REALTY_AVAILABLE,
  REALTY_OCCUPIED,
  RU,
  SHORT_TERM_TYPE,
  SUCCESS,
  TIMEZONE,
  UA,
  WARNING
} from './Constants';
import { HOUSING_ROUTE, OFFICE_ROUTE, PARKING_ROUTE } from './RouterContants';
import ObjectPhoto from './ObjectPhoto/ObjectPhoto';
import { differenceInDays, differenceInMonths, fromUnixTime } from 'date-fns';
import { format } from 'date-fns-tz';

export const notification = (type, message) => {
  const defaultMessage = <NotificationsMessage type={ type }
                                               message={ message }/>;
  switch ( type ) {
    case SUCCESS:
      return toast.success(defaultMessage);
    case WARNING:
      return toast.warn(defaultMessage);
    case INFO:
      return toast.info(defaultMessage);
    case ERROR:
      return toast.error(defaultMessage);
    default:
      return toast(defaultMessage);
  }

};


export const setPageTitle = (title) => {
  document.title = title;
};

export const unFormattedPhoneNumber = (phone) => {
  //eslint-disable-next-line
  const reg = new RegExp(/[\s\(\)\+\-\_,]/g);
  return phone.replace(reg, '');
};


export const handleLocalStorage = (key, value) => {
  if ( key && !value ) {
    return JSON.parse(localStorage.getItem(key));
  }

  if ( key && value ) {
    localStorage.setItem(key, value);
    return false;
  }
};

export const handleRemoveLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const getStringStatus = (status) => {
  switch ( status ) {
    case 1:
      return 'available';
    case 2:
      return 'reserved';
    case 3:
      return 'occupied';
    case 4:
      return 'cleaning-needed';
    case 5:
      return 'partial-rent';
    default:
      return null;
  }
};

export const getStringRentType = (rent_type) => {
  switch ( rent_type ) {
    case 1:
      return 'long-term';
    case 2:
      return 'short-term';
    default:
      return null;
  }
};

export const getPriceTypeFromRent = (rent_type) => {
  switch ( rent_type ) {
    case 1:
      return 'мес.';
    case 2:
      return 'сутки';
    default:
      return null;
  }
};

export const getRusStringRentType = (rent_type) => {
  switch ( rent_type ) {
    case 1:
      return 'Долгосрочная аренда';
    case 2:
      return 'Посуточная аренда';
    default:
      return null;
  }
};

export const getRusStringShortRentType = (rent_type) => {
  switch ( rent_type ) {
    case 1:
      return 'Долгосрочно';
    case 2:
      return 'Посуточно';
    default:
      return null;
  }
};


export const getRoomsCase = (room_number, short_variant) => {
  switch ( room_number ) {
    case -1:
      return `Квартира-студия`;
    case 1:
      return short_variant ? `${ room_number }` : `${ room_number } комната`;
    case 2:
      return short_variant ? `${ room_number }` : `${ room_number } комнаты`;
    case 3:
      return short_variant ? `${ room_number }` : `${ room_number } комнаты`;
    case 4:
      return short_variant ? `${ room_number }` : `${ room_number } комнаты`;
    case 5:
      return short_variant ? `${ room_number }` : `${ room_number } комнат`;
    default:
      return short_variant ? `${ room_number }` : `${ room_number } комната`;
  }
};

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16 | 0, v = c === 'x' ? r : ( r && 0x3 | 0x8 );
    return v.toString(16);
  });
};

export const valueFromId = (opts, id) => opts.find(o => o.value === id);
export const valueFromName = (opts, name) => opts.find(o => o.value === name);


export const validateOnlyNumber = (value) => {
  const reg = /^[0-9]+$/;

  return reg.test(value);
};

export const transformOfferType = (type) => {
  switch ( type ) {
    case 'Apartment':
      return HOUSING_ROUTE;
    case 'Parking':
      return PARKING_ROUTE;
    case 'Office':
      return OFFICE_ROUTE;
    default:
      return HOUSING_ROUTE;
  }
};

export function translateErrors(errors) {

  for ( let i = 0; i < errors.length; i++ ) {
    switch ( errors[i].type ) {
      case 'unsupportedFileType':
        return 'Некорректный тип файла';
      case 'maxSizeExceeded':
        return 'Превышен максимальный размер файла';
      case 'multipleMaxCountExceeded':
        return 'Превышен максимальное количество файлов';
      case 'multipleMaxSizeExceeded':
        return 'Превышен максимальный размер файлов';
      default:
        return 'Ошибка';
    }
  }
}

export function getFormatTypeDownloadDocument(type) {
  switch ( type ) {
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx';
    case 'application/msword' :
      return 'doc';
    case 'application/pdf':
      return 'pdf';
    case 'image/jpeg':
      return 'jpeg';
    case 'image/png':
      return 'png';
    case 'image/bmp':
      return 'bmp';
    default:
      return null;
  }
}


export const getImagesPathFromGallery = (images) => {
  if ( !!images.length ) {
    return images.map(i => (
      {
        original: i.path,
        thumbnail: i.path,
        renderItem: () => <ObjectPhoto className="image-gallery"
                                       image={ i.path }/>
      }
    ));
  }

  return null;

};

export const handleCreateParameterList = (realty) => {
  let parameters = [];

  if ( realty?.square ) {
    parameters.push({ title: 'Площадь общая', value: [`${ realty.square } `, 'м', <sup key={ uuidv4() }>2</sup>] });
  }

  if ( realty?.floor ) {
    parameters.push({ title: 'Этаж', value: realty.floor });
  }

  if ( realty?.section ) {
    parameters.push({ title: 'Секция', value: realty.section });
  }

  if ( realty?.rooms ) {
    parameters.push({ title: 'К-во комнат', value: getRoomsCase(realty.rooms, true) });
  }

  return parameters;

};

export const calculateDifferenceDaysAmount = (date_from, date_to, price, type) => {
  if ( type === LONG_TERM_TYPE ) {
    if ( date_from && date_to ) {
      let currentMonth = 0;
      let nextMothLastDay = 1;
      let differenceMothCount = differenceInMonths(date_to, date_from);

      if ( differenceMothCount > currentMonth || differenceMothCount === nextMothLastDay ) {
        return price * differenceMothCount;
      } else {
        return price;
      }
    }
  }

  if ( type === SHORT_TERM_TYPE ) {
    if ( date_from && date_to ) {
      let differenceDaysCount = differenceInDays(date_to, date_from);
      if ( differenceDaysCount < 0 ) {
        return price;
      }
      return price * differenceDaysCount;
    }
  }

  if ( !date_to || !date_from ) {
    return price;
  }
};

export const getRealtyStatus = (status) => {
  switch ( status ) {
    case 1:
    case 2:
    case 4:
    case 6:
    case 7:
      return REALTY_AVAILABLE;
    case 3:
    case 5:
      return REALTY_OCCUPIED;
    default:
      return REALTY_AVAILABLE;
  }
};

export const getLSVariableFromRedirect = (key, searchValue) => {
  if ( localStorage.getItem(key) ) {
    let a = JSON.parse(localStorage.getItem(key));
    return a.hasOwnProperty(searchValue);
  } else {
    return false;
  }
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};


export const formatChatMessageTime = (time, lng) => {
  if ( !time ) return;

  return format(fromUnixTime(time), 'd MMMM HH:mm', { timeZone: TIMEZONE, locale: lng });
};

export const getFormatNumber = (value) => {
  return new Intl.NumberFormat('ru-RU').format(value);
};

export const getLocalePhoneMask = () => {
  switch ( process.env.REACT_APP_CURRENT_LANGUAGE ) {
    case ( UA ):
      return {
        placeholder: '+380 (__) ___-__-__ ',
        mask: '+380 (99) 999-99-99'
      };
    case ( RU ):
      return {
        placeholder: '+7 (___) ___-__-__',
        mask: '+7 (999) 999-99-99'
      };
    case ( MD ):
      return {
        placeholder: '+373 (__) ___-___',
        mask: '+373 (99) 999-999'
      };
    default:
      return {
        placeholder: '+7 (___) ___-__-__',
        mask: '+7 (999) 999-99-99'
      };
  }
};

export const generateCurrCurrency = (number) => {
  let sum = parseInt(number);

  const CURRENCY_TYPES = {
    ru: {
      locale: 'ru-RU',
      currency: 'RUB'
    },
    ua: {
      locale: 'ua-UK',
      currency: 'UAH'
    }
  };

  let options = {
    style: 'currency',
    roundingMode: 'floor',
    unitDisplay: 'short',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    currency: CURRENCY_TYPES[process.env.REACT_APP_CURRENT_LANGUAGE].currency
  };

  let formatter = new Intl.NumberFormat(CURRENCY_TYPES[process.env.REACT_APP_CURRENT_LANGUAGE].locale, options);

  return formatter.format(sum);
};


export const generateCurrCurrencyManual = () => {
  const ALL_CURRENCY = {
    ru: 'руб.',
    ua: 'грн'
  };

  switch ( process.env.REACT_APP_CURRENT_LANGUAGE ) {
    case ( UA ):
      return ALL_CURRENCY.ua;
    case ( RU ):
      return ALL_CURRENCY.ru;
      case ( MD ):
      return ALL_CURRENCY.ru;
    default:
      return ALL_CURRENCY.ru;
  }
};
